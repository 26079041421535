import 'src/global.css';
import '@lightspeed/golf-b2c-design-system/theme.css';
import 'src/theme-overrides.css';
import 'src/react-datepicker.css';
import '@lightspeed/golf-b2c-design-system/Button/button.css';
import '@lightspeed/golf-b2c-design-system/ButtonIcon/buttonIcon.css';
import '@lightspeed/golf-b2c-design-system/Checkbox/checkbox.css';
import '@lightspeed/golf-b2c-design-system/Modal/modal.css';
import '@lightspeed/golf-b2c-design-system/RadioGroup/radioGroup.css';
import '@lightspeed/golf-b2c-design-system/Select/select.css';
import '@lightspeed/golf-b2c-design-system/Spinner/spinner.css';

import * as Tooltip from '@radix-ui/react-tooltip';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { camelCase, snakeCase } from 'lodash-es';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import ErrorBoundary from 'src/components/ErrorBoundary';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import { SessionWrapper } from 'src/contexts/session';
import { transformObjectKeys } from 'src/utils/casing';
import { useTxWithCache } from 'src/utils/i18n';
import { extractPagination } from 'src/utils/pagination';

const ZenDeskWidget = dynamic(
  () => import('src/components/ZendDeskWidget').then((m) => m.ZenDeskWidget),
  { ssr: false }
);

const GoogleTagManager = dynamic(
  () => import('src/components/GoogleTagManager').then((m) => m.GoogleTagManager),
  { ssr: false }
);

const DataDogInitializer = dynamic(
  () => import('src/components/DataDogInitializer').then((m) => m.DataDogInitializer),
  { ssr: false }
);

axios.defaults.headers.common.Accept = 'application/json';
if (typeof window === 'undefined') {
  axios.defaults.headers.common['Accept-Encoding'] = 'identity';
}
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  (request) => ({
    ...request,
    data: transformObjectKeys(snakeCase, request.data),
  }),
  null,
  { synchronous: true }
);
axios.interceptors.response.use(
  (response) => ({
    ...response,
    data: transformObjectKeys(camelCase, response.data),
  }),
  null
);
axios.interceptors.response.use(extractPagination, null);

const queryClient = new QueryClient();

export default function GolfMarketplaceApp({ Component, pageProps }: AppProps) {
  useTxWithCache(pageProps.txProps);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SessionWrapper>
          <Tooltip.Provider delayDuration={0}>
            <Head>
              {/* https://nextjs.org/docs/messages/no-document-viewport-meta */}
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <Header />
            <ErrorBoundary>
              <Component className="z-20" {...pageProps} />
            </ErrorBoundary>
            <Footer pageUrl={pageProps.pageUrl} />
          </Tooltip.Provider>
        </SessionWrapper>
      </QueryClientProvider>
      <GoogleTagManager />
      <DataDogInitializer />
      <ZenDeskWidget />
    </>
  );
}
